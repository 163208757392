@use "breakpoints";
@use "variables";
@use "colors";

.confirmation {
  background: #f9f9f9;
  color: variables.$black;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  min-block-size: 100vh;
  overflow: hidden;
  position: relative;

  ul {
    padding: 0;
  }

  hr {
    margin: 40px 0;
  }
}

.headline {
  flex: 0 0 100%;
}

.information {
  flex: 1 0 100%;
  padding: 40px 24px 24px;
}

.sub-copy {
  color: colors.$dark-gray;
  margin-block-start: 10px;
}

.cta {
  background-color: variables.$black;
  color: variables.$white;
  inline-size: 100%;
  margin-block-start: 30px;
  max-inline-size: none;

  &:focus,
  &:active {
    outline: 1px solid #000 !important;
    outline-offset: 3px !important;
  }
}

.sending-text {
  color: #757575;
  display: inline-block;
  font-size: 14px;
  margin-block-end: 2px;
  margin-inline-start: 5px;
}

.resent-text {
  margin-block-start: 1em;
}

.error-text {
  color: red;
  margin-block-start: 1em;
}

.check-mark {
  padding: 25px 0 10px;

  p {
    margin: 0;
  }

  a {
    border-block-end: 1px solid black;
  }

  svg {
    block-size: 20px;
    flex-shrink: 0;
    inline-size: 20px;

    g {
      transform: translate(0, 0);
    }
  }

  span {
    font-size: 14px;
    margin-inline-start: 30px;
  }
}

.club-image {
  text-align: end;

  img {
    max-block-size: 250px;
  }
}

@media (min-width: breakpoints.$sm) {
  .club-image {
    img {
      inline-size: 100%;
      max-block-size: 512px;
    }
  }
}

@media (min-width: breakpoints.$lg) {
  .confirmation {
    background: variables.$white;
    flex-direction: row;
  }

  .headline {
    flex-basis: 50%;
  }

  .information {
    block-size: 100vh;
    flex-basis: 50%;
    overflow-y: auto;
    padding: 120px 124px;
  }

  .cta {
    max-inline-size: 230px;
  }

  .club-image {
    img {
      max-inline-size: 556px;
    }
  }
}
